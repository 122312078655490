import "lazysizes";
import {
  Header,
  ContactBar,
  CarIntro,
  CopyUrl,
  Lightbox,
  ContactCenter,
  HeroTeaser,
  Navbar,
  Showcase,
  LanguageSwitch,
  InfoBox,
  Slider,
} from "lchh-styleguide";

import CarSearch from "./car-search";

new Header();
new LanguageSwitch(".language-switch");

window.addEventListener("DOMContentLoaded", () => {
  const showcases = document.querySelectorAll(".showcase");
  if (showcases)
    Array.from(showcases).forEach((item) => {
      new Showcase(item);
    });
  
  setTimeout(()=> {
    const num = document.querySelector(".js-count-number");
    if (num) new HeroTeaser(num, 1500);
  },1000)
  
});

// init navbar
const navbar = document.querySelector('.navbar');
if (navbar) {
  new Navbar(navbar);
}

// init copy url
const copyUrl = document.querySelector('.copy-url');
if (copyUrl) {
  new CopyUrl(copyUrl);
}

//init contact center on details page
const contactCenters = document.querySelectorAll(".contact-center");
if (contactCenters.length) {
  new ContactCenter();
}

// init car search
new CarSearch({
  els: {
    makeFilter: ".js-filter--make",
    modelFilter: ".js-filter--model",
    teaserListHolder: ".js-teaser-list",
    paginationHolder: ".js-pagination",
    headline: ".js-headline",
    resetButton: ".js-reset",
  },
  pageSize: 10,
});

//init all lightboxes
const lightboxes = document.querySelectorAll(".lightbox");
if (lightboxes)
  Array.from(lightboxes).forEach((item) => {
    new Lightbox(item);
  });

// init all lightboxes
const carIntro = document.querySelector(".car-intro");
if (carIntro) new CarIntro(carIntro);

// init contact bar
const contactBar = document.querySelector('.contact-bar');
if (contactBar) new ContactBar(contactBar);

// hide highlight with empty value
const highlightLabels = document.querySelectorAll(".highlight__label");
if (highlightLabels.length) {
  highlightLabels.forEach((hl) => {
    if (hl.innerHTML.trim().length == 0) hl.parentNode.style.display = "none";
  });
}

//init infobox
const infoBoxs = document.querySelectorAll(".info-box");
if (infoBoxs.length) {
  infoBoxs.forEach((infoBox) => {
    new InfoBox(infoBox)
  });
}

// init contact center on details page
const $sliders = document.querySelectorAll(".slider");
console.log($sliders);
if ($sliders.length) {
  $sliders.forEach((sliderEl) => {
    new Slider(sliderEl);
  });
}
